<template>
  <div class="hosted-pg" v-if="render">
    <vs-card class="mt-5">
      <div slot="header">
        <div class="flex justify-between items-center">
          <h3 class="text-2xl font-normal">Payment services</h3>
        </div>
      </div>
      <div class="mb-8">
        <div class="flex flex-wrap">
          <template v-if="payNowOptions.includes('CARDS')">
            <div class="md:col-start-3 md:col-end-11 bg-container-80">
              <p class="text-body mb-10">Payment methods enabled on your account are listed below. Card surcharges are automatically added to the amount you request from a customer and paid by your customer, not you.</p>
              <div class="grid grid-cols-2">
                <div>
                  <div class="flex justify-between">
                    <h5>Credit/debit card payments</h5>
                    <h5 class="w-12">Fee</h5>
                  </div>

                  <hr class="mt-2.5 mb-6" />

                  <div class="grid gap-y-3">
                    <div class="flex items-center justify-between" :key="index+0.9" v-for="(card, index) in cardLists">
                      <div class="flex items-center gap-x-4 w-200">
                        <img v-if="card.title.toLowerCase() == 'visa'" :src="visaCard" alt="visa" />
                        <img v-else-if="card.title.toLowerCase() == 'mastercard'" :src="masterCard" alt="masterCard" />
                        <img v-else-if="card.title.toLowerCase() == 'amex'" :src="amexCard" alt="amex" />
                        <img v-else-if="card.title.toLowerCase() == 'diners'" :src="dinersClubCard" alt="diners-club" />
                        <img v-else-if="card.title.toLowerCase() == 'china unionpay'" :src="unionPayCard" alt="unionPay" />
                        <p>{{ card.title }}</p>
                      </div>
                      <p>{{ `${addZeroes(card.surcharge)} %` }}</p>
                    </div>
                  </div>
                </div>
                <div class="ml-auto">
                  <label>Biller name on customer statement</label>
                  <vs-input :disabled="true" v-model="merchantDescriptor" />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <p>Card payments not enabled, contact your account manager to access online card payments via {{ appName }}</p>
          </template>
        </div>
      </div>
      <div class="mb-8">
        <h3 class="text-xl font-normal">EFT payments</h3>
        <hr class="my-3" />
        <p class="mt-5 mb-8 font-normal">For payment requests not enabled with a finance option, set the default option for offering EFT (bank transfers)</p>

        <template>
          <div class="w-full">
            <vs-radio v-model="eftOption" vs-value="PLATFORM_BANK_ACCOUNT" :vs-name="eftOption" class="mt-2" :disabled="!payNowOptions.includes('EFT')">Use {{ appName }} payment service</vs-radio>
            <vs-radio v-model="eftOption" vs-value="MERCHANT_BANK_ACCOUNT" :vs-name="eftOption" class="mt-2">Use my bank account</vs-radio>
            <vs-radio v-model="eftOption" vs-value="DO_NOT_DISPLAY" :vs-name="eftOption" class="mt-2">Don't offer bank transfers</vs-radio>
          </div>
          <div class="mt-8">
            <div class="ds-wrapper">
              <label class="plan-text mb-2 flex-no-wrap">Default reference on disbursement transaction
                <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '50px, 2px' }}}">
                  <template slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></template>
                  <div class="popper py-2 px-4 text-center custom-popper">
                    <p class="text-primary">
                      You can set the reference we'll use on our bank transfer to you, for disbursements initiated from card/EFT payments.
                      If you don't enter one at the time of creating a request, we'll use the data available in the request to create a reference for you.
                      We'll use the first available data field, in order of what you select here - drag and drop items to order them from first preference to last.
                    </p>
                  </div>
                </popper>
              </label>
            </div>

            <draggable :list="referenceOrder" class="cursor-move list-draggable mt-2 w-1/4">
              <transition-group>
                <vs-list-item v-for="listItem in referenceOrder" :key="listItem.value + 0.1" class="list-item">
                  <div class="flex items-center">
                    <feather-icon icon="ListIcon" svgClasses="h-3 w-3" />
                    <p class="text-sm font-normal text-black ml-1">{{ listItem.label }}</p>
                  </div>
                </vs-list-item>
              </transition-group>
            </draggable>
          </div>
        </template>
      </div>

      <div>
        <h3 class="text-xl font-normal">Communications</h3>
        <hr class="my-3" />
        <div class="flex mt-2">
          <vs-switch v-model="automatedReminder" name="automatedReminder"></vs-switch>
          <label class="plan-text mb-2 ml-5">Send automated reminder for unpaid requests</label>
        </div>
      </div>

      <vs-row class="block">
        <div class="button-section mb-4 mt-6 md:mt-8">
          <vs-button class="primary lg:mr-6 mr-4" @click="prepareSetEftOption()" size="large" v-round>Save</vs-button>
          <a class="mid-blue text-button text-sm pl-1" @click="$router.push({ name: 'settings' })">Cancel</a>
        </div>
      </vs-row>
    </vs-card>

    <vs-popup
      title='Confirm - disable "pay now" on your account?'
      header-icon="warning"
      class="close-icon-hidden custom-popup-decline"
      id="decline-popup"
      :active.sync="payNowWarningPopup"
    >
      <div class="w-full text-justify">
        <p class="flex mb-2">Enacting this change will mean your account won't offer any payment method for a customer to make a payment directly to you. "Pay-now" templates will be deleted, any "pending" payment requests created for "pay-now" only will be cancelled. Are you sure?</p>
      </div>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" @click="setEftOption" action-btn>Continue</vs-button>
        <vs-button color="danger" @click="payNowWarningPopup = false" size="large" action-btn class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import draggable from "vuedraggable";
import Popper from "vue-popperjs";

import S3ImageGenerator from "@/views/components/S3ImageGenerator";
const amexCard = require("@/assets/images/cards/amex.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const directDebitCard = require("@/assets/images/cards/directdebit.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const unionPayCard = require("@/assets/images/cards/unionpay.svg");
const visaCard = require("@/assets/images/cards/visa.svg");
import ToolTipIcon from "../../components/design-sys-components/icons/ToolTipIcon";

export default {
  components: {
    draggable,
    Popper,
    S3ImageGenerator,
    ToolTipIcon
  },

  data() {
    return {
      amexCard: amexCard,
      automatedReminder: false,
      dinersClubCard: dinersClubCard,
      directDebitCard: directDebitCard,
      masterCard: masterCard,
      unionPayCard: unionPayCard,
      visaCard: visaCard,
      appName: process.env.VUE_APP_NAME.split("Pay").join(""),
      cardLists: [],
      eftOption: "DO_NOT_DISPLAY",
      payNowOptions: ["CARDS", "EFT"],
      render: false,
      referenceOrder: [],
      defaultReferenceOrder: [
        {
          label: "Merchant reference",
          value: "MERCHANT_REFERENCE",
        },
        {
          label: "External ID (from HPP)",
          value: "EXTERNAL_ID",
        },
        {
          label: "Payment request ID",
          value: "PAYMENT_REQUEST_ID",
        },
      ],
      payNowWarningPopup: false,
      merchantDescriptor: ""
    };
  },

  methods: {
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("partner", ["updateEftOption"]),
    ...mapActions("paymentCardOption", ["fetchPaymentCardOptionByPartnerId"]),

    addPopUpClass(enabled) {
      if (enabled) {
        document.body.classList.add("custom-popup", "decline-popup");
      } else {
        document.body.classList.remove("custom-popup", "decline-popup");
      }
    },

    // GET CARD LISTS
    async getPartnerCards() {
      this.$vs.loading();
      await this.fetchPaymentCardOptionByPartnerId(this.partnerId).then((response) => {
        this.cardLists = response.data.data;
      }).catch((ex) => {
        this.$vs.notify({
          title: "Card",
          text: ex.response.data.message,
          color: "danger",
          icon: "icon-times-circle",
          position: "top-right",
        });

        if (ex.response.status === 400) {
          this.$router.push({ name: "settings" });
        }
      }).finally(() => this.$vs.loading.close());
    },

    prepareSetEftOption() {
      if (!this.payNowOptions.includes("CARDS") && this.eftOption === "DO_NOT_DISPLAY") {
        this.addPopUpClass(true);
        this.payNowWarningPopup = true;
      } else {
        this.setEftOption();
      }
    },

    // UPDATE EFT OPTION
    async setEftOption() {
      this.$vs.loading();
      const payload = {
        id: this.partnerId,
        data: {
          eftOption: this.eftOption,
          referenceOrder: this.referenceOrder,
          automatedReminder: this.automatedReminder
        }
      };

      await this.updateEftOption(payload).then((response) => {
        this.$vs.notify({
          title: "Eft payments",
          text: "Eft payments updated successfully",
          color: "success",
          icon: "icon-check-circle",
          position: "top-right",
          iconPack: "feather",
        });
      }).catch((ex) => {
        this.$vs.notify({
          title: "Card",
          text: ex.response.data.message,
          color: "danger",
          icon: "error",
          position: "top-right",
        });
      }).finally(() => {
        this.payNowWarningPopup = false;
        this.addPopUpClass(false);
        this.$vs.loading.close();
      });
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },
  },

  created() {
    this.getPartnerCards();
  },

  beforeMount() {
    this.checkLogin().then(result => {
      this.payNowOptions = result.data.data.payNowOptions;
      this.render = true;
      this.merchantDescriptor = result.data.data.merchantDescriptor || "";
      this.eftOption = result.data.data.eftOption;
      this.automatedReminder = result.data.data.automatedReminder;
      this.referenceOrder = result.data.data.referenceOrder && result.data.data.referenceOrder.length ? result.data.data.referenceOrder : JSON.parse(JSON.stringify(this.defaultReferenceOrder));
      this.referenceOrder = this.referenceOrder.filter(item => item.value != "EXTERNAL_ID");
    });
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return (this.user.userType == "admin") ? this.user._id : this.user.partnerId;
    }
  },
  watch: {
    payNowWarningPopup(val) {
      this.addPopUpClass(val);
    }
  }
};
</script>

